#mainContainer {
    height: inherit;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
}

@media (max-width: 375px) { 
    #mainNavBrandSlogan {
        font-size: medium;
    }
}

@media (max-width: 320px) { 
    #mainNavBrandSlogan {
        display: none;
    }
}

#mainContainerJumbotron {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    margin-bottom: unset;
    border-radius: unset;

    background-color: rgba(63, 104, 138, 0.25);
}

#mainFooter {
    display: flex;
    flex-direction: row;
}

#mainFooterMenuNav .nav-link {
    padding-top: 0;
    padding-bottom: 0;
}

#mainFooterMenuNav .divider {
    margin-left: .5ch;
    margin-right: .5ch;
}

@media (max-width: 768px) { 
    #mainFooter {
        align-items: center;
    }

    #mainFooterMenuNav {
        display: block;
    }

    #mainFooterMenuNav .divider {
        display: none;
    }

    #mainFooterMenuNav .nav-link {
        text-align: right;
        margin-bottom: 1ch;
    }
}

.spacer {
    flex: 1 1 auto;
}

#mainNavBrandSlogan {
    color: #353054 !important;
}

.nav-link {
    text-align: center;
    color: #353054 !important;
}

.nav-link:hover {
    color: rgba(0,0,0,.7) !important;
}

.nav-link.active {
    color: #1DA67C !important;
    font-weight: 900;
}

/*
 * read-more-links
 */
.link-button {
    color:#007bff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;
}

.link-button:hover,
.link-button:focus {
    color:#007bff;
    text-decoration: underline;
    background-color: transparent;
}